.write {
    padding-top: 50px;
  }
  
  .writeImg {
    margin-left: 150px;
    width: 70vw;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .writeForm {
    position: relative;
  }
  
  .writeFormGroup {
    margin-left: 150px;
    display: flex;
    align-items: center;
  }
  
  .writeIcon {
    width: 25px;
    height: 25px;
    font-size: 20px;
    border: 1px solid;
    border-radius: 50%;
    color: rgb(129, 125, 125);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .writeInput {
    font-size: 30px;
    border: none;
    padding: 20px;
    width: 70vw;
  }
  
  .writeInput::placeholder {
    color: rgb(189, 185, 185);
    font-weight: 400;
  }
  
  .writeInput:focus {
    outline-style: none;
  }
  
  .writeText {
    width: 70vw;
    height: 100vh;
    font-family: inherit;
    font-size: 20px;
  }
  
  .writeSubmit {
    position: absolute;
    top: 20px;
    right: 50px;
    color: white;
    background-color: teal;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .writeFile {
   
    top: 20px;
    margin-left: 10px;
    color: white;
    background-color: peru;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  [hidden] {
    display: none !important;
  }
  /* .post {
    width: 385px;
    margin: 0px 25px 40px 25px;
    display: flex;
    flex-direction: column;
  }
  
  .postImg {
    width: 385px;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
  }
  
  .postInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .postCat {
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 11px;
    color: #be9656;
    line-height: 19px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .postTitle {
    font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 900;
    margin-top: 15px;
    cursor: pointer;
  }
  
  .postDate {
    font-family: "Lora", serif;
    font-style: italic;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    margin-top: 15px;
  }
  
  .postDesc {
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .posts{
    flex: 9;
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
} */

* {box-sizing:border-box}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Make the images invisible by default */
.Containers {
  display: none;
}

/* forward & Back buttons */
.Back, .forward {
  cursor: pointer;
  position: absolute;
  top: 48%;
  width: auto;
  margin-top: -23px;
  padding: 17px;
  color: grey;
  font-weight: bold;
  font-size: 19px;
  transition: 0.4s ease;
  border-radius: 0 5px 5px 0;
  user-select: none;
}

/* Place the "forward button" to the right */
.forward {
  right: 0;
  border-radius: 4px 0 0 4px;
}

/*when the user hovers,add a black background with some little opacity */
.Back:hover, .forward:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption Info */
.Info {
  color: #e3e3e3;
  font-size: 16px;
  padding: 10px 14px;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

/* Worknumbering (1/3 etc) */
.MessageInfo {
  color: #f2f2f3;
  font-size: 14px;
  padding: 10px 14px;
  position: absolute;
  top: 0;
}

/* The circles or bullets and indicators */
.dots {
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin: 0 3px;
  background-color: #acc;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s ease;
}

.enable, .dots:hover {
  background-color: #717161;
}

/* Faint animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.4s;
  animation-name: fade;
  animation-duration: 1.4s;
}

@-webkit-keyframes fade {
  from {opacity: .5}
  to {opacity: 2}
}

@keyframes fade {
  from {opacity: .5}
  to {opacity: 2}
}
.cke_chrome{
  border-radius: 10px !important;
  border: 1px solid #695656 !important;
  border-width: thin !important;     
  width:100px;   
}
